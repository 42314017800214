import { ITableColumn } from '../interfaces/table-column.interface';

export const resultsValidationColumns: ITableColumn[] = [
    { field: 'announcement_description', header: 'RESULTS_VALIDATION.TABLE.ANNOUNCEMENT_DESCRIPTION', checked: true, sortable: false, editable: false },
    { field: 'user_id', header: 'RESULTS_VALIDATION.TABLE.USER_ID', checked: true, sortable: true, editable: false },
    { field: 'product_id', header: 'RESULTS_VALIDATION.TABLE.PRODUCT_ID', checked: true, sortable: false, editable: false },
    { field: 'sta_year', header: 'RESULTS_VALIDATION.TABLE.STA_YEAR', checked: true, sortable: true, editable: false },
    { field: 'contact_owner', header: 'RESULTS_VALIDATION.TABLE.CONTACT_OWNER', checked: true, sortable: true, editable: false },
    { field: 'tasting_status', header: 'RESULTS_VALIDATION.TABLE.TASTNG_STATUS', checked: true, sortable: true, editable: false },
    { field: 'test_date', header: 'RESULTS_VALIDATION.TABLE.TEST_DATE', checked: true, sortable: true, editable: false },
    { field: 'food_drink', header: 'RESULTS_VALIDATION.TABLE.FOOD_DRINK', checked: true, sortable: true, editable: false },
    { field: 'category_name', header: 'RESULTS_VALIDATION.TABLE.CATEGORY', checked: true, sortable: true, editable: false },
    { field: 'candidate_award', header: 'RESULTS_VALIDATION.TABLE.CANDIDATE_AWARD', checked: true, sortable: true, editable: false },
    { field: 'test_score', header: 'RESULTS_VALIDATION.TABLE.TEST_SCORE', checked: true, sortable: true, editable: false },
    { field: 'test_stars', header: 'RESULTS_VALIDATION.TABLE.TEST_STARS', checked: true, sortable: true, editable: false },
    { field: 'past_score', header: 'RESULTS_VALIDATION.TABLE.PAST_SCORE', checked: true, sortable: false, editable: false },
    { field: 'past_stars', header: 'RESULTS_VALIDATION.TABLE.PAST_STARS', checked: true, sortable: true, editable: false },
    // { field: 'stars_var', header: 'RESULTS_VALIDATION.TABLE.STARS_VAR', checked: true, sortable: true, editable: false },
    { field: 'score_var', header: 'RESULTS_VALIDATION.TABLE.SCORE_VAR', checked: true, sortable: true, editable: false },
    { field: 'auto_score', header: 'RESULTS_VALIDATION.TABLE.AUTO_SCORE', checked: true, sortable: true, editable: false },
    { field: 'final_score', header: 'RESULTS_VALIDATION.TABLE.FINAL_SCORE', checked: true, sortable: true, editable: true },
    { field: 'final_stars', header: 'RESULTS_VALIDATION.TABLE.FINAL_STARS', checked: true, sortable: true, editable: false },
    { field: 'modified', header: 'RESULTS_VALIDATION.TABLE.MODIFIED', checked: true, sortable: true, editable: false },
    { field: 'final_award', header: 'RESULTS_VALIDATION.TABLE.FINAL_AWARD', checked: true, sortable: true, editable: false },
];

export const resultsValidationFrozenColumns: ITableColumn[] = [
    { field: 'checkbox', header: '', sortable: false, editable: false, checked: true },
    { field: 'tasting_id', header: 'RESULTS_VALIDATION.TABLE.TASTING_ID', checked: true, sortable: true, editable: true },
    { field: 'company_name', header: 'RESULTS_VALIDATION.TABLE.COMPANY_NAME', checked: true, sortable: true, editable: false },
    { field: 'product_name', header: 'RESULTS_VALIDATION.TABLE.PRODUCT_NAME', checked: true, sortable: true, editable: false },
];
