import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { resultsValidationColumns, resultsValidationFrozenColumns } from '../../data/results-validation-columns';
import { ResultValdiationToolSortConditionEnum } from '../../enums/result-validation-tool-filters.enum';
import { IFilter } from '../../interfaces/filter.interface';
import {
    ICalibrationResultDtoIn,
    IResultValidation,
    IResultValidationsDto,
    IResultValidationsQueryOptions,
    IResultValidationsStats,
    IResultValidationsTableData,
    ITastingScoreComments,
} from '../../interfaces/results-validation.interface';
import { ResultValidationToolService } from '../../services/result-validation.service';
import { rowPerPageOptions } from '../../utils/table.utils';
import { ResultValidations } from '../actions/results-validation.actions';
import { SharedTablesSelectors } from '../selectors/shared-tables.selectors';
import { TableStateModel } from './table.state';

export interface ResultValidationStateModel extends TableStateModel<IResultValidationsTableData, IResultValidationsQueryOptions> {
    data: IResultValidation[];
    multipleSelectedRows: IResultValidation[];
    currentTastingScoresComments: ITastingScoreComments;
    stats: IResultValidationsStats;
}

const queryOptionsDefaults: IResultValidationsQueryOptions = {
    pageNumber: 1,
    countToFetch: 10,
    sortCondition: '-' + ResultValdiationToolSortConditionEnum.TASTING_CREATED_AT,
    filters: [],
    textFilter: '',
};
@State<ResultValidationStateModel>({
    name: 'resultsValidation',
    defaults: {
        data: [],
        tableData: [],
        scrollableColumns: [...resultsValidationColumns],
        frozenColumns: [...resultsValidationFrozenColumns],
        queryOptions: queryOptionsDefaults,
        totalRecords: null,
        rowPerPageOptions,
        showFilters: false,
        filtersData: [],
        multipleSelectedRows: [],
        currentTastingScoresComments: null,
        stats: null,
    },
})
@Injectable()
export class ResultValidationsState extends SharedTablesSelectors {
    constructor(private resultValidationToolService: ResultValidationToolService) {
        super();
    }

    @Action(ResultValidations.GetFilters)
    public getResulsFilters(ctx: StateContext<ResultValidationStateModel>): any {
        return this.resultValidationToolService.getFilters().pipe(
            tap((result: IFilter[]) => {
                ctx.patchState({
                    filtersData: result,
                });
            })
        );
    }

    @Action(ResultValidations.FetchAll)
    public getAllResults(ctx: StateContext<ResultValidationStateModel>): Observable<IResultValidationsDto> {
        const state: ResultValidationStateModel = ctx.getState();
        return this.resultValidationToolService.getAllResults(state.queryOptions).pipe(
            tap((result: IResultValidationsDto) => {
                if (result) {
                    const tableData: IResultValidationsTableData[] = this.formatTableData(result.scores);
                    ctx.patchState({
                        data: result.scores,
                        tableData,
                        totalRecords: result.pagination.total_items,
                    });
                }
            })
        );
    }

    @Action(ResultValidations.SetQueryOptions)
    public setQueryOptions(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.SetQueryOptions): void {
        const state: ResultValidationStateModel = ctx.getState();

        /* Either reset query options based on flag, or set it to existing state options */
        const baseOptions: IResultValidationsQueryOptions = action.flags.resetUnchangedFields ? queryOptionsDefaults : state.queryOptions;

        ctx.setState(
            patch<ResultValidationStateModel>({
                queryOptions: {
                    ...baseOptions,
                    ...action.options,
                },
            })
        );
    }

    @Action(ResultValidations.UpdateColumnPreferences)
    public updateColumnPreferences(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.UpdateColumnPreferences): void {
        ctx.setState(
            patch<ResultValidationStateModel>({
                scrollableColumns: action.columns,
            })
        );
    }

    @Action(ResultValidations.ResetColumnPreferences)
    public resetColumnPreferences(ctx: StateContext<ResultValidationStateModel>): void {
        ctx.setState(
            patch<ResultValidationStateModel>({
                scrollableColumns: resultsValidationColumns,
            })
        );
    }

    @Action(ResultValidations.ToggleFilters)
    public setFiltersDisplay(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.ToggleFilters): void {
        ctx.setState(
            patch<ResultValidationStateModel>({
                showFilters: action.showFilters,
            })
        );
    }

    @Action(ResultValidations.SetMultipleSelectedRows)
    public setMultipleSelectedRows(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.SetMultipleSelectedRows): void {
        const state: ResultValidationStateModel = ctx.getState();

        const multipleSelectedRows: IResultValidation[] = state.data.filter((resultData: IResultValidation) =>
            action.tastingIds.includes(resultData.product.tasting.id)
        );

        ctx.setState(
            patch<ResultValidationStateModel>({
                multipleSelectedRows,
            })
        );
    }

    @Action(ResultValidations.Validate)
    public validate(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.Validate): Observable<void> {
        return this.resultValidationToolService.validateTastings(action.tastingIds);
    }

    @Action(ResultValidations.Invalidate)
    public invalidate(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.Invalidate): Observable<void> {
        return this.resultValidationToolService.invalidateTastings(action.tastingIds);
    }

    @Action(ResultValidations.GetScoresAndComments)
    public getScoresAndComments(
        ctx: StateContext<ResultValidationStateModel>,
        action: ResultValidations.GetScoresAndComments
    ): Observable<ITastingScoreComments> {
        return this.resultValidationToolService.getScoresAndComments(action.tastingId).pipe(
            tap((result: ITastingScoreComments) => {
                if (result) {
                    ctx.patchState({
                        currentTastingScoresComments: result,
                    });
                }
            })
        );
    }

    @Action(ResultValidations.Calibrate)
    public calibrate(
        ctx: StateContext<ResultValidationStateModel>,
        action: ResultValidations.Calibrate
    ): Observable<ICalibrationResultDtoIn> {
        return this.resultValidationToolService.calibrate(action.calibration);
    }

    @Action(ResultValidations.UpdateFinalScore)
    public updateFinalScore(ctx: StateContext<ResultValidationStateModel>, action: ResultValidations.UpdateFinalScore): Observable<void> {
        return this.resultValidationToolService.updateFinalScore(action.tastingId, action.newScore);
    }

    @Action(ResultValidations.GetStats)
    public getStats(ctx: StateContext<ResultValidationStateModel>): Observable<IResultValidationsStats> {
        return this.resultValidationToolService.getStats(ctx.getState().queryOptions).pipe(
            tap((result: IResultValidationsStats) => {
                if (result) {
                    ctx.patchState({
                        stats: result,
                    });
                }
            })
        );
    }

    @Action(ResultValidations.ResetStats)
    public resetStats(ctx: StateContext<ResultValidationStateModel>): void {
        ctx.patchState({
            stats: null,
        });
    }

    private formatTableData(results: IResultValidation[]): IResultValidationsTableData[] {
        const resultTable: IResultValidationsTableData[] = [];
        results.forEach((r: IResultValidation) => {
            resultTable.push({
                tasting_id: r.product.tasting.id,
                company_name: r.product.user.company_name,
                product_name: r.product.name,
                sta_year: r.product.tasting.sta_year,
                tasting_status: r.product.tasting.status,
                product_id: r.product.id,
                user_id: r.product.user.id,
                candidate_award: r.product.tasting.candidate_award,
                test_score: +r.test_score.total,
                test_stars: r.test_score.stars,
                past_stars: r.past_stars,
                past_score: r.past_score,
                stars_var: r.stars_var,
                score_var: r.score_var,
                auto_score: +r.auto_score.total,
                final_score: +r.final_score.total,
                final_stars: r.final_score.stars,
                modified: r.modified,
                final_award: r.product.tasting.prestige_award,
                test_date: r.test_date,
                food_drink: r.product.category.criteria_type,
                category_id: r.product.category.id,
                category_name: r.product.category.name ?? null,
                contact_owner: r.product.user.contact_owner,
                country: r.product.user.country,
                firstname: r.product.user.firstname,
                lastname: r.product.user.lastname,
                email: r.product.user.email,
                announcement_description: r.product.announcement.description
            });
        });
        return resultTable;
    }
}
